import LeftNavigationBar from '../../shareableResources/LeftNavigationBar'
import './index.css'
import backIcon from '../../../assets/images/rightContainer/backIcon.svg'
import plusIcon from '../../../assets/images/rightContainer/plusIcon.svg'
import editSchoolIcon from '../../../assets/images/rightContainer/editSchoolIcon.svg'
import removeSchoolIcon from '../../../assets/images/rightContainer/removeSchoolIcon.svg'
import closeBtnIcon from '../../../assets/images/rightContainer/closeBtnIcon.svg'
import { useNavigate } from 'react-router-dom'
import StickyLabel from '../../shareableResources/StickyLabel'
import noSchoolListIcon from '../../../assets/images/rightContainer/noSchoolListIcon.svg'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Input, message, Modal } from 'antd';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect, useRef, useState } from 'react'


const ViewSchoolProfile = () => {
    const navigate = useNavigate(); 

    const schoolList = () => (
        <div className='schoolList'>
            <h3 className='schoolList_schoolName'>Croswell Early Childhood Center</h3>
            <p className='schoolList_schoolType'>Middle school</p>
        </div>
    )

    const listOfCommunity = (title, sub) => (
        <div className='d-flex'>
            <p className='schoolList_title'>{title}</p>
            <p className='schoolList_sub'>{sub}</p>
        </div>
    )
    const [open, setOpen] = useState(false);
    const [openEditCommunity, setopenEditCommunity] = useState(false);
    const [scroll, setScroll] = useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };
    const handleCloseAddCommunity = (event, reason) => {
        if (reason !== 'backdropClick') {
            setopenEditCommunity(false);
        }


    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';
    const addedSchoolMessage = () => {
        messageApi
            .open({
                type: 'loading',
                content: 'Adding new School',
                duration: 1.5,
            })
            .then(() => message.success('School has been added successfully.', 4.5, setOpen(false)))
        // .then(() => message.info('Community Added Successfully', 4.5));
    };
   
    const [deleteSchoolModalOpen, deleteSchoolOpen] = useState(false);
    const removeSchool = () => {
     
        message
            .loading({
                content: 'Deleting..',
                duration: 1.5,
            })
            .then(() => {
                message.success('Community has been deleted successfully.', 4.5);
                deleteSchoolOpen(false);
            })
            .then(() => {
                navigate('/community'); // Replace with your target route
            });
        // .then(() => message.info('Community Added Successfully', 4.5));
    }; 



    const [isSchoolCard, isSchoolCardActive] = useState(false);

    const emptySchoolCard = () => (
        <div className='emptySchoolCard'>
            <img src={noSchoolListIcon} alt='noSchoolListIcon' />
            <p className='noCommunityText'>No Community list</p>
        </div>
    )

    const addedCommunityMessage = () => {
        messageApi
            .open({
                type: 'loading',
                content: 'Editing Community',
                duration: 1.5,
            })
            .then(() => message.success('Community Edited Successfully', 1.5, setopenEditCommunity(false),))
        // .then(() => message.info('Community Added Successfully', 2.5));
    };

    

    return (
        <div className='d-flex'>
            <LeftNavigationBar />
            <div className='rightCommunityList_container'>
                {/* <div className='schoolList_container'>
                    <div className='schoolListTitle_Contianer'>
                        <button className='btn-none' type='button' onClick={() => navigate("/community")} ><img src={backIcon} alt='backIcon' /></button>
                        <div>
                            <h2 className='schoolListContianer_title'>Romeo Community Community</h2>
                            <p className='viewProfile'>View profile</p>
                        </div>
                    </div>
                    {isSchoolCard ? (schoolList()) : (emptySchoolCard())} 
                    
                </div> */}
                <StickyLabel />
                <div className='schoolDetails_container'>
                <div className='schoolListTitle_Contianer mb-0 pb-0 gap-0'>
                        <div className='d-flex justify-content-between align-items-center w-100'>
                        <div className='d-flex align-items-baseline'>
                        <button className='btn-none pl-0' type='button' onClick={() => navigate("/community-school-list")} ><img src={backIcon} alt='backIcon' /></button>
                        <div>
                            <h2 className='schoolListContianer_title'>Romeo Community Schools</h2>
                            <p className='communityContent'>School District</p>
                        </div>
                        </div>
                        <div className='d-flex align-items-center gap-3 whitespace-nowrap'>
                            <button className='btn-none editSchool_btn' type='button' onClick={() => setopenEditCommunity(true)}  ><img src={editSchoolIcon} alt='plusIcon' /> Edit Community</button>
                            <button className='btn-none removeSchool_btn' type='button' onClick={() => deleteSchoolOpen(true)}><img src={removeSchoolIcon} alt='plusIcon' /> Remove Community</button>
                        </div>
                        {contextHolder}
                        </div>
                    </div>
                    <div className='schoolDetailHeader'>
                        <h4 className='communityDetailHeader_title'>Community details</h4>
                       
                    </div>
                    <div className='schoolDetailListContianer'>
                        <div className='listOfSchoolDetails'>
                            {listOfCommunity("School Name:", "Croswell Early Childhood Center")}
                            {listOfCommunity("School type:", "Middle school")}
                            {listOfCommunity("Address:", "175 Croswell St.Romeo, MI 48065")}
                            {listOfCommunity("School ID:", "#15435")}
                            {listOfCommunity("Phone:", "(586) 752-0314")}
                            {listOfCommunity("Fax:", "(586) 336-6823")}
                            {listOfCommunity("Employees:", "0")}
                            {listOfCommunity("School Status:", "Active")}
                        </div>
                         
                        {contextHolder}
                        <Dialog
                    className='right_popup'
                    open={openEditCommunity}
                    onClose={handleCloseAddCommunity}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
                    <div>
                        <div className='d-flex w-100 align-items-center justify-content-between'>
                            <h1 className='modal_title'>Edit Community</h1>
                            <button className='btn-none' type='button' onClick={handleCloseAddCommunity} ><img src={closeBtnIcon} alt='closeBtnIcon' /></button>
                        </div>
                        {contextHolder}

                        <div className='container-fluid'>

                            <div className='row row-gap-3 mt-3'>
                                <div className='col-md-12'>
                                    <label className='modal_label'>School name</label>
                                    <Input className='border-input' />
                                </div>

                                <div className='col-md-6'>
                                    <label className='modal_label'>Email address</label>
                                    <Input className='border-input' />
                                </div>
                                <div className='col-md-6'>
                                    <label className='modal_label'>School ID</label>
                                    <Input className='border-input' />
                                </div>
                            </div>


                        </div>
                    </div>

                    <DialogActions>
                        <button className='cancel_btn' onClick={handleCloseAddCommunity}>Cancel</button>
                        <button className='add_btn' onClick={addedCommunityMessage}>Save</button>
                    </DialogActions>
                </Dialog>

                        <Modal
                            title="Remove Community"
                            centered
                            open={deleteSchoolModalOpen}
                            onOk={removeSchool}
                            onCancel={() => deleteSchoolOpen(false)} 
                            okButtonProps={{ style: { backgroundColor: '#FB434C', borderColor: '#FB434C', color: '#fff' } }}
                            okText="Delete" 
                        >{contextHolder}
                            <p>Do you wish to remove the Community? All the Community data will be deleted and cannot be restored again.</p>
                            <Input className='border-input' placeholder='Type the school group name' />
                        </Modal>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default ViewSchoolProfile