import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./component/Login";
import Community from "./component/communityModule/Community";
import CommunitySchoolList from "./component/communityModule/CommunitySchoolList";
import ViewSchoolProfile from "./component/communityModule/ViewSchoolProfile";
import Instructor from "./component/instructorModule/Instructor";
import InstructorList from "./component/instructorModule/InstructorList";
import Reports from "./component/reportsModule/Reports";
import MyLibrary from "./component/myLibraryModule/MyLibrary";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import Settings from "./component/settingsModule/Settings";
import Dashboard from "./component/dashboardModule/Dashboard";
import MedicalConditionList from "./component/dashboardModule/MedicalConditionList";
import CreateSchool from "./component/CreateSchool";
import ForgotPassword from "./component/ForgotPassword";
import Schools from "./component/Schools";
import {
  ContentProtectedRoute,
  OnboardingProtectedRoute,
} from "./component/ProtectedRoute/protectedRoute";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateJwtToken } from "./globalDataManager/UserDetails/userDetails";
import { useDispatch } from "react-redux";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(location);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/sign-in");
    }

    dispatch(updateJwtToken());
  }, [location.pathname]);

  return (
    <Routes>
      <Route element={<OnboardingProtectedRoute />}>
        <Route exact path="/sign-in" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
      </Route>

      <Route element={<ContentProtectedRoute />}>
        <Route exact path="/create-school" element={<CreateSchool />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        {/* <Route exact path="/medical-condition-list" element={<MedicalConditionList />} /> */}
        <Route exact path="/community" element={<Community />} />
        <Route
          exact
          path="/community-school-list"
          element={<CommunitySchoolList />}
        />
        <Route
          exact
          path="/view-school-profile"
          element={<ViewSchoolProfile />}
        />
        <Route exact path="/instructor" element={<Instructor />} />
        <Route exact path="/instructor-list" element={<InstructorList />} />
        <Route exact path="/my-library" element={<MyLibrary />} />
        <Route exact path="/reports" element={<Reports />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/schools" element={<Schools />} />
      </Route>
    </Routes>
  );
}

export default App;
