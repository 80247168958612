import './index.css'

const StickyLabel = () => {

    return(
        <div className='stickyLabel'>
            <p className='stickyLabelContent'>© 2024 <b>KalariCorp</b>. All Rights Reserved</p>
        </div>
    )
}

export default StickyLabel