import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jwtToken: "",
};

export const userDetails = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    updateJwtToken: (state, action) => {
      const currentToken = JSON.parse(localStorage.getItem("admin_token"));

      state.jwtToken = currentToken;
    },
  },
});

export const jwtToken = (state) => state.userDetails.jwtToken;
export const { updateJwtToken } = userDetails.actions;
export default userDetails.reducer;
