import LeftNavigationBar from '../../shareableResources/LeftNavigationBar'
import './index.css'
import backIcon from "../../../assets/images/rightContainer/backIcon.svg"
import plusIcon from '../../../assets/images/rightContainer/plusIcon.svg'
import editSchoolIcon from '../../../assets/images/rightContainer/editSchoolIcon.svg'
import removeSchoolIcon from '../../../assets/images/rightContainer/removeSchoolIcon.svg'
import closeBtnIcon from '../../../assets/images/rightContainer/closeBtnIcon.svg'
import { useNavigate } from 'react-router-dom'
import StickyLabel from '../../shareableResources/StickyLabel'
import noSchoolListIcon from '../../../assets/images/rightContainer/noSchoolListIcon.svg'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Input, message, Modal } from 'antd';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect, useRef, useState } from 'react'


const CommunitySchoolList = () => {
    const navigate = useNavigate();

    const schoolList = () => (
        <div className='schoolList'>
            <h3 className='schoolList_schoolName'>Croswell Early Childhood Center</h3>
            <p className='schoolList_schoolType'>Middle school</p>
        </div>
    )

    const listOfSchools = (title, sub) => (
        <div className='d-flex'>
            <p className='schoolList_title'>{title}</p>
            <p className='schoolList_sub'>{sub}</p>
        </div>
    )
    const [open, setOpen] = useState(false);
    const [openEditSchool, setOpenEditSchool] = useState(false);
    const [openAddCommunity, setopenAddCommunity] = useState(false);
    const [scroll, setScroll] = useState('paper');

    const addNewSchoolOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const addNewSchoolClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };
    const editSchoolOpen = (scrollType) => () => {
        setOpenEditSchool(true);
        setScroll(scrollType);
    };

    const editSchoolClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpenEditSchool(false);
        }
    };
    const handleCloseAddCommunity = (event, reason) => {
        if (reason !== 'backdropClick') {
            setopenAddCommunity(false);
        }


    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';
    const addedSchoolMessage = () => {
        messageApi
            .open({
                type: 'loading',
                content: 'Adding new School',
                duration: 1.5,
            })
            .then(() => message.success('School has been added successfully.', 4.5, setOpen(false)))
        // .then(() => message.info('Community Added Successfully', 4.5));
    };
    const editeddSchoolMessage = () => {
        messageApi
            .open({
                type: 'loading',
                content: 'Editing School Details',
                duration: 1.5,
            })
            .then(() => message.success('School has been edited successfully.', 4.5, setOpenEditSchool(false)))
        // .then(() => message.info('Community Added Successfully', 4.5));
    };
   
    const [deleteSchoolModalOpen, deleteSchoolOpen] = useState(false);
    const removeSchool = () => {
     
        messageApi
            .open({
                type: 'loading',
                content: 'Deleting..',
                duration: 1.5,
            })
            .then(() => message.success('School has been deleted successfully.', 4.5, deleteSchoolOpen(false)))
        // .then(() => message.info('Community Added Successfully', 4.5));
    }; 



    const [isSchoolCard, isSchoolCardActive] = useState(true);

    const emptySchoolCard = () => (
        <div className='emptySchoolCard'>
            <img src={noSchoolListIcon} alt='noSchoolListIcon' />
            <p className='noCommunityText'>No Schools list</p>
        </div>
    )


    return (
        <div className='d-flex'>
            <LeftNavigationBar />
            <div className='rightCommunityList_container'>
                <div className='schoolList_container'>
                    <div className='schoolListTitle_Contianer'>
                        <button className='btn-none' type='button' onClick={() => navigate("/community")} ><img src={backIcon} alt='backIcon' /></button>
                        <div>
                            <h2 className='schoolListContianer_title'>Romeo Community Schools</h2>
                            <p className='viewProfile' onClick={()=> navigate('/view-school-profile')}>View profile</p>
                        </div>
                    </div>
                    {isSchoolCard ? (schoolList()) : (emptySchoolCard())} 
                    <StickyLabel />
                </div>
                <div className='schoolDetails_container'>
                    <div className='schoolDetailHeader'>
                        <h4 className='schoolDetailHeader_title'>School details</h4>
                        <button className='addNewSchool_btn' type='button' onClick={addNewSchoolOpen('paper')}><img src={plusIcon} alt='plusIcon' /> Add new school</button>
                    </div>
                    <div className='schoolDetailListContianer'>
                        <div className='listOfSchoolDetails'>
                            {listOfSchools("School Name:", "Croswell Early Childhood Center")}
                            {listOfSchools("School type:", "Middle school")}
                            {listOfSchools("Address:", "175 Croswell St.Romeo, MI 48065")}
                            {listOfSchools("School ID:", "#15435")}
                            {listOfSchools("Phone:", "(586) 752-0314")}
                            {listOfSchools("Fax:", "(586) 336-6823")}
                            {listOfSchools("Employees:", "0")}
                            {listOfSchools("School Status:", "Active")}
                        </div>
                        <div className='d-flex align-items-center gap-3 whitespace-nowrap'>
                            <button className='btn-none editSchool_btn' type='button' onClick={editSchoolOpen('paper')} ><img src={editSchoolIcon} alt='plusIcon' /> Edit School</button>
                            <button className='btn-none removeSchool_btn' type='button' onClick={() => deleteSchoolOpen(true)}><img src={removeSchoolIcon} alt='plusIcon' /> Remove School</button>
                        </div>
                        {contextHolder}
                        <Dialog
                            className='right_popup'
                            open={openEditSchool}
                            onClose={editSchoolClose}
                            scroll={scroll}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
                            <div>
                                <div className='d-flex w-100 align-items-center justify-content-between'>
                                    <h1 className='modal_title'>Edit school</h1>
                                    <button className='btn-none' type='button' onClick={addNewSchoolClose} ><img src={closeBtnIcon} alt='closeBtnIcon' /></button>
                                </div>
                                <div className='container-fluid'>

                                    <div className='row row-gap-3 mt-3'>
                                        <div className='col-md-12'>
                                            <label className='modal_label'>School name</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='modal_label'>School type</label>
                                            <br />
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">

                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='modal_label'>Status</label>
                                            <br />
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">

                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='modal_label'>Phone</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='modal_label'>Fax</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='modal_label'>Address line 1</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='modal_label'>Address line 2</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='modal_label'>City</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='modal_label'>Zip</label>
                                            <br />
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">

                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='modal_label'>County</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='modal_label'>State</label>
                                            <br />
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">

                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='modal_label'>Country</label>
                                            <br />
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">

                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <DialogActions>
                                <button className='cancel_btn' onClick={addNewSchoolClose}>Cancel</button>
                                <button className='add_btn' onClick={editeddSchoolMessage}>Edit</button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            className='right_popup'
                            open={open}
                            onClose={addNewSchoolClose}
                            scroll={scroll}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
                            <div>
                                <div className='d-flex w-100 align-items-center justify-content-between'>
                                    <h1 className='modal_title'>Add school</h1>
                                    <button className='btn-none' type='button' onClick={addNewSchoolClose} ><img src={closeBtnIcon} alt='closeBtnIcon' /></button>
                                </div>
                                <div className='container-fluid'>

                                    <div className='row row-gap-3 mt-3'>
                                        <div className='col-md-12'>
                                            <label className='modal_label'>School name</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='modal_label'>School type</label>
                                            <br />
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">

                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='modal_label'>Status</label>
                                            <br />
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">

                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='modal_label'>Phone</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='modal_label'>Fax</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='modal_label'>Address line 1</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='modal_label'>Address line 2</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='modal_label'>City</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='modal_label'>Zip</label>
                                            <br />
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">

                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='modal_label'>County</label>
                                            <Input className='border-input' />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='modal_label'>State</label>
                                            <br />
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">

                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='modal_label'>Country</label>
                                            <br />
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">

                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <DialogActions>
                                <button className='cancel_btn' onClick={addNewSchoolClose}>Cancel</button>
                                <button className='add_btn' onClick={addedSchoolMessage}>Add</button>
                            </DialogActions>
                        </Dialog>

                        <Modal
                            title="Remove school"
                            centered
                            open={deleteSchoolModalOpen}
                            onOk={removeSchool}
                            onCancel={() => deleteSchoolOpen(false)} 
                            okButtonProps={{ style: { backgroundColor: '#FB434C', borderColor: '#FB434C', color: '#fff' } }}
                            okText="Delete" 
                        >{contextHolder}
                            <p>Do you wish to remove the school? All the school data will be deleted and cannot be restored again.</p>
                            <Input className='border-input' placeholder='Type the school group name' />
                        </Modal>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default CommunitySchoolList