import "./index.css";
import logo from "../../../assets/images/leftNavigate/logo.svg";
import schoolsIcon from "../../../assets/images/leftNavigate/schoolsIcon.svg";
import instructorIcon from "../../../assets/images/leftNavigate/instructorIcon.svg";
import myLibraryIcon from "../../../assets/images/leftNavigate/myLibraryIcon.svg";
import reportIcon from "../../../assets/images/leftNavigate/reportIcon.svg";
import logoIcon from "../../../assets/images/leftNavigate/logoIcon.svg";
import logoSmIcon from "../../../assets/images/leftNavigate/logoSmIcon.svg";
import KalariGroupIcon from "../../../assets/images/leftNavigate/KalariGroupIcon.svg";
import supportIcon from "../../../assets/images/leftNavigate/supportIcon.svg";
import collapseBtn from "../../../assets/images/leftNavigate/collapseBtn.svg";
import dashboardIcon from "../../../assets/images/leftNavigate/dashboardIcon.svg";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown } from "antd";

const LeftNavigationBar = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('admin_token'); // Clear the token or relevant data from localStorage
    navigate('/sign-in'); // Redirect to the login page after logout
  };

  const navigateBtn = (navIcon, navContent, url) => (
    <button className="leftNav_btn" onClick={() => navigate(url)}>
      <img className="btn_icon" src={navIcon} alt="schoolsIcon" /> {navContent}
    </button>
  );

  const items = [
    // {
    //   key: "1",
    //   label: (
    //     <p onClick={() => navigate("/settings")} className="menu_dropdown_item">
    //     <svg
    //       width="17"
    //       height="16"
    //       viewBox="0 0 17 16"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <g clipPath="url(#clip0_1811_85805)">
    //         <path
    //           d="M8.98438 10C9.51481 10 10.0235 9.78929 10.3986 9.41421C10.7737 9.03914 10.9844 8.53043 10.9844 8C10.9844 7.46957 10.7737 6.96086 10.3986 6.58579C10.0235 6.21071 9.51481 6 8.98438 6C8.45394 6 7.94523 6.21071 7.57016 6.58579C7.19509 6.96086 6.98438 7.46957 6.98438 8C6.98438 8.53043 7.19509 9.03914 7.57016 9.41421C7.94523 9.78929 8.45394 10 8.98438 10Z"
    //           stroke="#334155"
    //           stroke-width="1.2"
    //           stroke-miterlimit="10"
    //           stroke-linecap="round"
    //           stroke-linejoin="round"
    //         />
    //         <path
    //           d="M2.31738 8.5863V7.41297C2.31738 6.71963 2.88405 6.1463 3.58405 6.1463C4.79072 6.1463 5.28405 5.29297 4.67738 4.2463C4.33072 3.6463 4.53738 2.8663 5.14405 2.51963L6.29738 1.85963C6.82405 1.5463 7.50405 1.73297 7.81738 2.25963L7.89072 2.3863C8.49072 3.43297 9.47738 3.43297 10.084 2.3863L10.1574 2.25963C10.4707 1.73297 11.1507 1.5463 11.6774 1.85963L12.8307 2.51963C13.4374 2.8663 13.644 3.6463 13.2974 4.2463C12.6907 5.29297 13.1841 6.1463 14.3907 6.1463C15.0841 6.1463 15.6574 6.71297 15.6574 7.41297V8.5863C15.6574 9.27963 15.0907 9.85297 14.3907 9.85297C13.1841 9.85297 12.6907 10.7063 13.2974 11.753C13.644 12.3596 13.4374 13.133 12.8307 13.4796L11.6774 14.1396C11.1507 14.453 10.4707 14.2663 10.1574 13.7396L10.084 13.613C9.48405 12.5663 8.49738 12.5663 7.89072 13.613L7.81738 13.7396C7.50405 14.2663 6.82405 14.453 6.29738 14.1396L5.14405 13.4796C4.85345 13.3123 4.6411 13.0365 4.55361 12.7128C4.46612 12.3891 4.51064 12.0439 4.67738 11.753C5.28405 10.7063 4.79072 9.85297 3.58405 9.85297C2.88405 9.85297 2.31738 9.27963 2.31738 8.5863Z"
    //           stroke="#334155"
    //           stroke-width="1.2"
    //           stroke-miterlimit="10"
    //           stroke-linecap="round"
    //           stroke-linejoin="round"
    //         />
    //       </g>
    //       <defs>
    //         <clipPath id="clip0_1811_85805">
    //           <rect
    //             width="16"
    //             height="16"
    //             fill="white"
    //             transform="translate(0.984375)"
    //           />
    //         </clipPath>
    //       </defs>
    //     </svg>
    //     Settings
    //   </p>
    //   ),
    // },
    {
      key: "2",
      label: (
        <p className="menu_dropdown_item_logout" onClick={() => handleLogout()}>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.77137 9.74634L10.478 8.03967L8.77137 6.33301M3.65137 8.03967H10.4314"
              stroke="#E81C28"
              stroke-width="1.2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.98438 2.66699C11.931 2.66699 14.3177 4.66699 14.3177 8.00033C14.3177 11.3337 11.931 13.3337 8.98438 13.3337"
              stroke="#E81C28"
              stroke-width="1.2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Log out
        </p>
      ),
    },
  ];

  return (
    <div className="leftNavigate_container">
      <div className="d-flex flex-column left_top">
       <img className="logo" src={logoIcon} alt="logoIcon"/>

        {navigateBtn(dashboardIcon, "Dashboard", "/dashboard")}
        {/* {navigateBtn(schoolsIcon, "Schools", "/schools")} */}
        {/* {navigateBtn(instructorIcon, "Instructors", "/instructor")}
        {navigateBtn(myLibraryIcon, "My Library", "/my-library")} */}
        {/* {navigateBtn(reportIcon, "Reports", "/reports")} */}
        {/* {navigateBtn(supportIcon, "Support")} */}
        {/* {navigateBtn(supportIcon, "Approval System")} */}
      </div>
      <div className="leftNav_bottom">
        <Dropdown
          menu={{
            items,
          }}
          placement="topLeft"
        >
          <div className="d-flex align-items-center gap-2">
            <img src={logoSmIcon} alt="KalariGroupIcon" />
            <p className="kalariGroup">Romeo School District</p>
          </div>
        </Dropdown>

        {/* <button className="btn-none">
          <img src={collapseBtn} alt="collapseBtn" />
        </button> */}
      </div>
    </div>
  );
};

export default LeftNavigationBar;
