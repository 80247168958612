import {store} from "../../store/store"

const rootDomain = "https://admin.yogabykalari.com/api/";
//const rootDomain = "https://localhost:7073/";


const getAuthToken = () => {
  const state = store.getState();
  return state.userDetails.jwtToken;
};

const headers = {
  "Content-Type": "application/json",
  offset: new Date().getTimezoneOffset().toString(),
};


const headersWithToken = (token) => ({
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${token.replace(/^"(.*)"$/, '$1')}`,
  DataBaseName: "KalariHarmony"
});

/* 
! signIn POST API
*/

export const postUserNameAndPassword = async (content) => {
  const POST_API = `${rootDomain}User/Authenticate`;

  
  console.log(POST_API, "api");
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(content),
  };

  try {
    const response = await fetch(POST_API, options);
    console.log(response);

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
};

/* 
! Dashboard school listing API
*/

export const getDashboardSchoolListingService = async (navigate) => {
  const GET_API = `${rootDomain}Dashboard/getSchoolModellisting`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      localStorage.removeItem("admin_token")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
};


/* 
! Medical Condition listing API
*/

export const getMedicalConditionService = async (navigate) => {
  const GET_API = `${rootDomain}Dashboard/getMedicalconditionmodelsCount`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      localStorage.removeItem("admin_token")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
};


/* 
! Employees by Gender API
*/

export const getEmployeesByGenderService = async (navigate) => {
  const GET_API = `${rootDomain}Dashboard/userGenderlistcount`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      localStorage.removeItem("admin_token")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
};


/* 
! Employees by age API
*/

export const getEmployeesByAgeService = async (navigate) => {
  const GET_API = `${rootDomain}Dashboard/getEmployeesbyagecountlist`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      localStorage.removeItem("admin_token")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
};
