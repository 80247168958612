import LeftNavigationBar from '../../shareableResources/LeftNavigationBar'
import './index.css'
import plusIcon from '../../../assets/images/rightContainer/plusIcon.svg'
import communityIcon from '../../../assets/images/rightContainer/communityIcon.svg'
import closeBtnIcon from '../../../assets/images/rightContainer/closeBtnIcon.svg'
import noCommunityCardIcon from '../../../assets/images/rightContainer/noCommunityCardIcon.svg'
import StickyLabel from '../../shareableResources/StickyLabel'
import { useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Input, message } from 'antd';
import { useEffect, useRef, useState } from 'react'

const Community = () => {
    const navigate = useNavigate();

    const schoolsCard = () => (
        <div className='schoolsCard' onClick={() => navigate("/community-school-list")}>
            <div className='d-flex bd_cus'>
                <img src={communityIcon} alt='communityIcon' />
                <div>
                    <h2 className='communityName'>Romeo Community Schools</h2>
                    <p className='coummnityCount'>--</p>
                </div>
            </div>
            <p className='schoolId'>School ID:  #15432</p>
        </div>
    )
    const [open, setOpen] = useState(false);
    const [openAddCommunity, setopenAddCommunity] = useState(false);
    const [scroll, setScroll] = useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };
    const handleCloseAddCommunity = (event, reason) => {
        if (reason !== 'backdropClick') {
            setopenAddCommunity(false);
        }


    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';
    const addedCommunityMessage = () => {
        messageApi
            .open({
                type: 'loading',
                content: 'Adding new Community',
                duration: 1.5,
            })
            .then(() => message.success('Community Added Successfully', 1.5, setopenAddCommunity(false)))
        // .then(() => message.info('Community Added Successfully', 2.5));
    };
    const [isSchoolCard, isSchoolCardActive] = useState(true);

    const emptySchoolCard = () => (
        <div className='emptySchoolCard'>
            <img src={noCommunityCardIcon} alt='noCommunityCardIcon' />
            <p className='noCommunityText'>No Community added yet</p>
        </div>
    )

    return (
        <div className='d-flex'>
            <LeftNavigationBar />
            <div className='rightCommunity_container'>
                <div className='d-flex justify-content-between align-items-center head_sticky'>
                    <div >
                        <h1 className='communityTitle'>Community</h1>
                        <p className='communityContent'>Add and edit all communities in this section</p>
                    </div>
                    <button className='outline_btn' onClick={() => setopenAddCommunity(true)} ><img src={plusIcon} alt='plusIcon' />Add new community</button>
                </div>
                <StickyLabel />
                <div className='schoolsContainer'>

                    {isSchoolCard ? (schoolsCard()) : (emptySchoolCard())}

                </div>
                <Dialog
                    className='right_popup'
                    open={openAddCommunity}
                    onClose={handleCloseAddCommunity}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
                    <div>
                        <div className='d-flex w-100 align-items-center justify-content-between'>
                            <h1 className='modal_title'>Add Community</h1>
                            <button className='btn-none' type='button' onClick={handleCloseAddCommunity} ><img src={closeBtnIcon} alt='closeBtnIcon' /></button>
                        </div>
                        {contextHolder}

                        <div className='container-fluid'>

                            <div className='row row-gap-3 mt-3'>
                                <div className='col-md-12'>
                                    <label className='modal_label'>School name</label>
                                    <Input className='border-input' />
                                </div>

                                <div className='col-md-6'>
                                    <label className='modal_label'>Email address</label>
                                    <Input className='border-input' />
                                </div>
                                <div className='col-md-6'>
                                    <label className='modal_label'>School ID</label>
                                    <Input className='border-input' />
                                </div>
                            </div>


                        </div>
                    </div>

                    <DialogActions>
                        <button className='cancel_btn' onClick={handleCloseAddCommunity}>Cancel</button>
                        <button className='add_btn' onClick={addedCommunityMessage}>Add</button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default Community